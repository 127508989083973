.swiper {
    padding: 2rem 0;
}

.partners .swiper {
    background: var(--main-color-opacity-20);
    border-radius: .5rem;
}

.reviews .swiper-wrapper {
    gap: 1rem;
}

.reviews .swiper-slide {
    text-align: center;
}

.reviews .swiper-scrollbar {
    opacity: .5 !important;
    background: #3d3d3d;
}

.reviews .swiper-scrollbar-drag {
    background-color: #fff;
}

.swiper-pagination-bullet-active {
    width: 1.3rem;
    border-radius: .2rem;
    -webkit-border-radius: .2rem;
    -moz-border-radius: .2rem;
    -ms-border-radius: .2rem;
    -o-border-radius: .2rem;
}

.partners .swiper-wrapper {
    align-items: center;
}